$variable-prefix: 'tblr-' !default;

// BASE CONFIG
$enable-social-colors: true !default;
$enable-extra-colors: true !default;
$enable-gradients: false !default;
$enable-navbar-vertical: true !default;
$enable-dark-mode: true !default;
$enable-negative-margins: false !default;

// ASSETS BASE
$assets-base: '..' !default;

// FONTS
$google-font: 'Inter' !default;
$google-font-monospaced: null !default;

$font-family-sans-serif: $google-font, -apple-system, BlinkMacSystemFont,
  San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif !default;
$font-family-monospace: $google-font-monospaced, Menlo, Monaco, Consolas,
  Liberation Mono, Courier New, monospace;
$font-family-serif: 'Georgia', 'Times New Roman', times, serif !default;

// EXTERNAL FONTS
$icon-fonts: () !default;

//Icons
$icon-stroke-width: 1.5 !default;
$icon-size: 1.25rem !default;

//Fonts
$font-size-base: 0.725rem !default;
$body-letter-spacing: 0 !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

$line-height-base: divide(1.25rem, $font-size-base) !default;
$line-height-sm: divide(1rem, $font-size-base) !default;
$line-height-lg: divide(1.5rem, $font-size-base) !default;

$h1-font-size: divide(24rem, 16) !default;
$h1-line-height: divide(2rem, $h1-font-size) !default;

$h2-font-size: divide(20rem, 16) !default;
$h2-line-height: divide(1.75rem, $h2-font-size) !default;

$h3-font-size: divide(16rem, 16) !default;
$h3-line-height: divide(1.5rem, $h3-font-size) !default;

$h4-font-size: $font-size-base !default;
$h4-line-height: divide(1.25rem, $h4-font-size) !default;

$h5-font-size: divide(12rem, 16) !default;
$h5-line-height: divide(1rem, $h5-font-size) !default;

$h6-font-size: divide(10rem, 16) !default;
$h6-line-height: divide(1rem, $h6-font-size) !default;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
) !default;

$small-font-size: percentage(divide($h5-font-size, $font-size-base)) !default;
$code-font-size: $small-font-size !default;
$code-line-height: 1.25rem !default;

$lead-font-size: $font-size-base !default;
$lead-font-weight: $font-weight-normal !default;

$blockquote-font-size: $font-size-base !default;

// COLORS
$min-contrast-ratio: 1.5 !default;
$text-muted-opacity: 0.7 !default;
$text-muted-light-opacity: 0.4 !default;
$text-muted-dark-opacity: 0.8 !default;

$border-opacity: 0.16 !default;
$border-dark-opacity: 0.24 !default;

$light: #f4f6fa !default;
$dark: #232e3c !default;

$body-bg: $light !default;
$body-color: $dark !default;

$color-contrast-dark: $dark !default;
$color-contrast-light: $light !default;

$light-black: rgba($dark, 0.24) !default;
$light-mix: rgba(mix($light, $dark, 64%), 0.24) !default;
$light-white: rgba($light, 0.24) !default;

$min-black: rgba($dark, 0.024) !default;
$min-white: rgba(mix($light, $dark, 48%), 0.1) !default;

$gray-50: #fbfbfb !default;
$gray-100: $light !default;
$gray-200: mix($light, $dark, 98%) !default;
$gray-300: mix($light, $dark, 94%) !default;
$gray-400: mix($light, $dark, 88%) !default;
$gray-500: mix($light, $dark, 78%) !default;
$gray-600: mix($light, $dark, 60%) !default;
$gray-700: mix($light, $dark, 36%) !default;
$gray-800: mix($light, $dark, 16%) !default;
$gray-900: $dark !default;

$blue: #206bc4 !default;
$azure: #4299e1 !default;
$indigo: #4263eb !default;
$purple: #ae3ec9 !default;
$pink: #d6336c !default;
$red: #d63939 !default;
$orange: #f76707 !default;
$yellow: #f59f00 !default;
$lime: #74b816 !default;
$green: #2fb344 !default;
$teal: #0ca678 !default;
$cyan: #17a2b8 !default;
$black: #000000 !default;
$white: #ffffff !default;

$text-muted: mix(
  $body-color,
  #ffffff,
  percentage($text-muted-opacity)
) !default;
$text-muted-light: mix(
  $body-color,
  #ffffff,
  percentage($text-muted-light-opacity)
) !default;
$text-muted-dark: mix(
  $body-color,
  #ffffff,
  percentage($text-muted-dark-opacity)
) !default;

$border-color: #e7e9ed !default;
$border-color-transparent: rgba($text-muted, $border-opacity) !default;

$border-color-dark: mix(
  $text-muted,
  #ffffff,
  percentage($border-dark-opacity)
) !default;
$border-color-dark-transparent: rgba(
  $text-muted,
  $border-dark-opacity
) !default;

$active-bg: rgba($blue, 0.06) !default;
$hover-bg: rgba($text-muted, 0.06) !default;

$primary: $blue !default;
$secondary: $text-muted !default;
$success: $green !default;
$info: $azure !default;
$warning: $orange !default;
$danger: $red !default;

$code-color: $primary !default;

$social-colors: (
  'facebook': #3b5998,
  'twitter': #1da1f2,
  'linkedin': #0a66c2,
  'google': #dc4e41,
  'youtube': #ff0000,
  'vimeo': #1ab7ea,
  'dribbble': #ea4c89,
  'github': #181717,
  'instagram': #e4405f,
  'pinterest': #bd081c,
  'vk': #6383a8,
  'rss': #ffa500,
  'flickr': #0063dc,
  'bitbucket': #0052cc,
  'tabler': #206bc4,
) !default;

$colors: (
  'blue': $blue,
  'azure': $azure,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'lime': $lime,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
) !default;

// Dark mode
$dark-mode-darken: darken($dark, 2%) !default;
$dark-mode-lighten: lighten($dark, 2%) !default;
$dark-mode-text: $light;

// Borders
$border-width: 1px !default;
$border-width-wide: 2px !default;

$border-radius-sm: 2px !default;
$border-radius: 4px !default;
$border-radius-lg: 8px !default;
$border-radius-pill: 100rem !default;

// Avatars
$avatar-size: 2.5rem !default;
$avatar-sizes: (
  'xs': 1.25rem,
  'sm': 2rem,
  'md': 3.75rem,
  'lg': 5rem,
  'xl': 7rem,
  '2xl': 11rem,
) !default;
$avatar-border-radius: $border-radius !default;
$avatar-font-size: $h4-font-size;

$link-decoration: none !default;
$link-hover-decoration: underline !default;

//Typography
$headings-font-weight: $font-weight-bold !default;

$hr-opacity: $border-opacity !default;
$hr-margin-y: 2rem !default;

$caret-spacing: 0.4em !default;

//Sizing
$grid-gutter-width: 1rem !default;
$container-padding-x: 1.5rem !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 2,
  5: $spacer * 4,
) !default;

$negative-spacers: if(
  $enable-negative-margins,
  negativify-map($spacers),
  null
) !default;

$size-spacers: (
  auto: auto,
  px: 1px,
  full: 100%,
) !default;

$size-values: map-merge(
  $spacers,
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  )
) !default;

$container-variations: (
  tight: 30rem,
  narrow: 45rem,
) !default;

$content-padding-y: 1.5rem !default;

$aspect-ratios: (
  '1x1': 100%,
  '4x3': calc(3 / 4 * 100%),
  '3x4': calc(4 / 3 * 100%),
  '16x9': calc(9 / 16 * 100%),
  '9x16': calc(16 / 9 * 100%),
  '21x9': calc(9 / 21 * 100%),
  '9x21': calc(21 / 9 * 100%),
) !default;

// overlay
$overlay-gradient: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0.6) 100%
) !default;

//accordion
$accordion-border-color: $border-color-transparent !default;
$accordion-icon-width: 1rem !default;

$accordion-button-active-bg: transparent !default;
$accordion-button-active-color: inherit !default;

$accordion-button-focus-border-color: $accordion-border-color !default;

//alerts
$alert-padding-x: 1rem !default;
$alert-padding-y: 1rem !default;
$alert-link-font-weight: $font-weight-bold !default;

$alert-border-width: 1px !default;
$alert-border-color: $border-color-transparent !default;
$alert-shadow: rgba($dark, 0.04) 0 2px 4px 0 !default;

//breadcrumb
$breadcrumb-divider-color: $text-muted !default;
$breadcrumb-active-color: inherit !default;

$breadcrumb-variants: (
  dots: '·',
  arrows: '›',
  bullets: '\02022',
) !default;

//badges
$badge-font-size: $code-font-size !default;
$badge-line-height: $code-line-height !default;
$badge-font-weight: $font-weight-medium !default;
$badge-empty-size: 0.5rem !default;

//buttons
$input-color: $body-color !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-padding-y: 0.5rem - 0.0625rem !default;

$input-btn-font-size-sm: $h5-font-size !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-padding-y-sm: 0.125rem !default;

$input-btn-font-size-lg: $h2-font-size !default;
$input-btn-padding-x-lg: 1.5rem !default;
$input-btn-padding-y-lg: 0.5rem !default;

$input-btn-focus-width: 0.25rem !default;

$input-height: null !default;
$input-height-sm: null !default;
$input-height-lg: null !default;
$input-color: inherit !default;
$input-focus-color: inherit !default;

//buttons
$btn-padding-x: 1rem !default;
$btn-font-weight: $font-weight-medium !default;
$btn-border-radius: $border-radius !default;

//cards
$card-title-spacer-y: 1.25rem !default;

$card-border-width: $border-width !default;
$card-border-color: $border-color-transparent !default;
$card-border-radius: $border-radius !default;

$card-cap-bg: $white !default;
$card-cap-color: $text-muted !default;
$card-cap-padding-x: 1rem !default;
$card-cap-padding-y: 0.75rem !default;

$card-spacer-x: 1rem !default;

$card-status-size: $border-width-wide !default;
$card-group-margin: 1.5rem !default;

$card-shadow: rgba($dark, 0.04) 0 2px 4px 0 !default;
$card-shadow-hover: rgba($dark, 0.16) 0 2px 16px 0 !default;

$cards-grid-gap: 1rem !default;
$cards-grid-breakpoint: lg !default;

//carousel
$carousel-control-color: $white !default;
$carousel-control-icon-width: 1.5rem !default;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$carousel-control-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='15 18 9 12 15 6'></polyline></svg>") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$carousel-control-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='9 18 15 12 9 6'></polyline></svg>") !default;

//close
$btn-close-width: 0.75rem !default;
$btn-close-opacity: 0.3 !default;

//dropdown
$dropdown-item-padding-x: 0.75rem !default;
$dropdown-item-padding-y: 0.5rem !default;
$dropdown-font-size: $font-size-base !default;
$dropdown-border-color: $border-color-transparent !default;
$dropdown-divider-bg: $dropdown-border-color !default;
$dropdown-padding-y: 0.25rem !default;
$dropdown-link-color: inherit !default;
$dropdown-link-hover-bg: $hover-bg !default;
$dropdown-link-hover-color: inherit !default;
$dropdown-spacer: 1px !default;
$dropdown-min-width: 11rem !default;
$dropdown-max-width: 25rem !default;

$dropdown-scrollable-height: 13rem !default;

$dropdown-link-active-color: $primary !default;
$dropdown-link-active-bg: $active-bg !default;

//loader
$loader-size: 2.5rem !default;

//lists
$list-group-header-bg: $light !default;

$list-group-border-color: $border-color !default;
$list-group-item-padding-y: $card-cap-padding-y !default;
$list-group-item-padding-x: $card-cap-padding-x !default;

//modals
$modal-backdrop-opacity: 0.24 !default;
$modal-backdrop-bg: $dark !default;
$modal-backdrop-blur: 4px !default;

$modal-fade-transform: translate(0, -1rem) !default;

$modal-content-border-color: transparent !default;
$modal-content-bg: $gray-50 !default;
$modal-content-border-radius: $border-radius !default;

$modal-header-padding: 1.5rem !default;
$modal-header-height: 3.5rem !default;
$modal-header-border-width: 1px !default;
$modal-header-border-color: $border-color !default;
$modal-header-bg: $light !default;
$modal-inner-padding: 1.5rem !default;
$modal-footer-border-width: 1px !default;
$modal-footer-margin-between: 0rem !default;
$modal-footer-bg: $light !default;
$modal-status-size: $border-width-wide !default;

$modal-xl: 1140px !default;
$modal-lg: 720px !default;
$modal-md: 540px !default;
$modal-sm: 380px !default;

//nav
$nav-link-color: inherit !default;
$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 0.75rem !default;

$nav-pills-link-active-color: $primary !default;
$nav-pills-link-active-bg: $active-bg !default;

$nav-bordered-border-color: $border-color !default;
$nav-bordered-border-width: $border-width !default;
$nav-bordered-link-active-color: $primary !default;
$nav-bordered-link-active-border-color: $primary !default;
$nav-bordered-link-active-border-width: 2 * $border-width !default;
$nav-bordered-margin-x: 1.25rem !default;

//navbar
$navbar-height: 3.5rem !default;
$navbar-padding-y: 0.25rem !default;

$navbar-light-color: rgba($body-color, $text-muted-opacity) !default;
$navbar-light-brand-color: $body-color !default;
$navbar-light-border-color: $border-color-transparent !default;
$navbar-light-active-color: $body-color !default;
$navbar-light-disabled-color: rgba($body-color, 0.3) !default;

$navbar-dark-color: rgba($white, $text-muted-opacity) !default;
$navbar-dark-brand-color: $white !default;
$navbar-dark-border-color: $border-color-transparent !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.3) !default;

$navbar-brand-padding-y: $nav-link-padding-y !default;
$navbar-brand-image-height: 2rem !default;
$navbar-brand-margin-right: 0 !default;
$navbar-brand-font-size: $h3-font-size !default;
$navbar-brand-font-weight: $font-weight-bold !default;

$navbar-toggler-font-size: 1rem !default;
$navbar-toggler-padding-x: 0 !default;
$navbar-toggler-padding-y: 0 !default;
$navbar-toggler-animation-time: 0.2s !default;
$navbar-toggler-focus-width: 0 !default;

$navbar-nav-link-padding-x: $nav-link-padding-x !default;

$navbar-overlap-height: 9rem !default;

//sidebar
$sidebar-width: 15rem !default;

//popover
$popover-border-color: $border-color !default;

//footer
$footer-padding-y: 2rem !default;
$footer-bg: $white !default;
$footer-border-color: $border-color !default;
$footer-border-color: $border-color !default;

//pagination
$pagination-border-width: 0 !default;
$pagination-padding-y: 0.25rem !default;
$pagination-padding-x: 0.25rem !default;
$pagination-color: $text-muted !default;
$pagination-bg: transparent !default;
$pagination-disabled-bg: transparent !default;
$pagination-disabled-color: $gray-500 !default;

//steps
$steps-border-width: 2px !default;
$steps-color-inactive: #f3f5f5 !default;

//spinner
$spinner-width: 1.5rem !default;
$spinner-height: 1.5rem !default;
$spinner-width-sm: 1rem !default;
$spinner-height-sm: 1rem !default;
$spinner-border-width: 2px !default;
$spinner-border-width-sm: 1px !default;

//tables
$table-color: inherit !default;
$table-head-border-color: $border-color !default;
$table-head-padding-y: 1rem !default;
$table-head-color: $text-muted !default;
$table-head-bg: $light !default;
$table-striped-order: even !default;
$table-striped-bg: $light !default;
$table-group-separator-color: $border-color !default;
$table-hover-bg: $light !default;

//toasts
$toast-border-color: $border-color !default;
$toast-header-color: $text-muted !default;

//progress
$progress-bg: $border-color !default;
$progress-height: 0.5rem !default;

//ribbons
$ribbon-margin: 0.25rem !default;

$list-group-border-color: $border-color !default;
$list-group-action-color: inherit !default;
$list-group-bg: inherit !default;

$input-disabled-bg: $gray-100 !default;
$input-border-color: $border-color !default;
$input-border-color-transparent: $border-color-dark-transparent !default;
$input-placeholder-color: $text-muted-light !default;

$input-group-addon-bg: $light !default;
$input-group-addon-color: $text-muted !default;

$input-border-radius: $border-radius !default;

//Forms
$form-check-margin-bottom: 0.5rem !default;
$form-check-input-width: 1rem !default;
$form-check-padding-start: $form-check-input-width + 0.5rem !default;
$form-check-input-bg: $white !default;
$form-check-input-border: 1px solid $input-border-color-transparent !default;
$form-check-input-border-radius: $border-radius !default;
$form-check-input-checked-bg-size: 1rem !default;

$form-check-input-checked-color: $white !default;
$form-check-input-checked-bg-repeat: repeat !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/></svg>") !default;
$form-check-input-checked-bg-image-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'><path fill='none' stroke='#{$dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><circle r='3' fill='#{$form-check-input-checked-color}' cx='8' cy='8' /></svg>") !default;

$form-check-input-checked-border-color: $input-border-color-transparent !default;

$form-check-label-disabled-opacity: $text-muted-opacity;

$form-select-indicator-color: $border-color-dark !default;

$form-switch-width: 2rem !default;
$form-switch-height: 1.125rem !default;
$form-switch-padding-start: $form-switch-width + 0.5rem !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$border-color}'/></svg>") !default;
$form-switch-bg-size: auto !default;

$form-range-track-height: 0.25rem !default;
$form-range-track-bg: $light-mix !default;
$form-range-thumb-border: 2px solid $white !default;
$form-range-thumb-height: 1rem !default;
$form-range-thumb-focus-box-shadow-width: 0.125rem !default;

$form-feedback-icon-valid: str-replace(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='" + $green + "' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='20 6 9 17 4 12'></polyline></svg>"),
  '#',
  '%23'
) !default;
$form-feedback-icon-invalid: str-replace(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='" + $red + "' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>"),
  '#',
  '%23'
) !default;

$form-label-font-size: 0.875rem !default;
$form-label-font-weight: 500 !default;
$form-label-color: $text-muted;

$caret-width: 0.36em !default;

//Flags
$flag-sizes: $avatar-sizes !default;

//Payments
$payment-sizes: $avatar-sizes !default;

// datagrid
$datagrid-padding: 1.5rem !default;
$datagrid-item-width: 15rem !default;
