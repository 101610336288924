.bg-white-overlay {
  color: $white;
  background-color: $light-white;
}

.bg-dark-overlay {
  color: $white;
  background-color: $light-black;
}

.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
