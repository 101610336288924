/**
Markdown
 */
.markdown {
  line-height: $line-height-lg;

  > :first-child {
    margin-top: 0;
  }

  > :last-child,
  > :last-child .highlight {
    margin-bottom: 0;
  }

  > hr {
    @include media-breakpoint-up(md) {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  }

  > {
    h1, h2, h3, h4, h5, h6 {
      font-weight: $font-weight-bold;
    }
  }

  > table {
    @extend .table, .table-bordered;
  }

  > blockquote {
    font-size: $h3-font-size;
    margin: 1.5rem 0;
    padding: .5rem 1.5rem;
  }

  > img {
    border-radius: $border-radius;
  }
}