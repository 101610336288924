%validation-lite {
  border-color: $input-border-color;
}

@each $state, $data in $form-validation-states {
  .form-control.is-#{$state}-lite {
    @extend %validation-lite;
  }

  .form-select.is-#{$state}-lite {
    @extend %validation-lite;
  }
}
