// $primary: #1A262C;
$secondary: #0a0e4d;
$dark: #202020;
$primary: #f16821;
// $secondary: #0567B3;
$grey: #848484;

$enable-negative-margins: true;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
);

@import './core/tabler.scss';
@import './_element-ui.css';



body {
  background-color: #f5f7fb !important;
  font-size: 13px !important;
  // overflow: hidden !important;
  overflow-x: hidden;
  overflow-y: overlay;
  scrollbar-width: thin !important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

.btn {
  font-size: 13px !important;
}

.form-label {
  margin-bottom: 0px !important;
  font-size: 0.725rem !important;
}

td {
  font-size: 13px !important;
}

.title {
  margin: 0;
  line-height: 24px;
  font-weight: 500;
}

.border-box {
  box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
}

.bg-nav-primary {
  background-color: rgba($primary, 0.06) !important;
}

.navbar-vertical {
  z-index: unset !important;
  padding-top: 56px;
}

//========================font size=================//

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-7 {
  font-size: 0.735rem !important;
}

.fs-8 {
  font-size: 0.725rem !important;
}

.fs-9 {
  font-size: 0.625rem !important;
}

//==================font weight================//
.fw-thin {
  font-weight: 100 !important;
}

.fw-extra-light {
  font-weight: 200 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-extra-bold {
  font-weight: 800 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.no-carret {
  .dropdown-toggle {
    &:after {
      display: none !important;
    }
  }
}

.carret-dark {
  .dropdown-toggle {
    &:after {
      content: '';
      display: inline-block;
      vertical-align: 0.306em;
      width: 0.36em;
      height: 0.36em;
      border-bottom: 1px solid;
      border-left: 1px solid;
      margin-right: 0.1em;
      margin-bottom: 3px;
      margin-left: 0.4em;
      transform: rotate(-45deg);
      border-color: $dark;
    }
  }
}

.split-carret {
  .dropdown-toggle {
    &:after {
      content: '';
      display: inline-block;
      vertical-align: 0.306em;
      width: 0.36em;
      height: 0.36em;
      border-bottom: 1px solid;
      border-left: 1px solid;
      margin-right: 0.7em;
      margin-bottom: 3px;
      margin-left: 0.7em;
      transform: rotate(-45deg);
    }
  }
}

.gm-style-cc {
  display: none !important;
}

.img-responsive {
  padding-top: unset !important;
}

// .dropdown-toggle {
//   &:after {
//     content: '' !important;
//     display: inline-block !important;
//     vertical-align: 0.306em !important;
//     width: 0.36em !important;
//     height: 0.36em !important;
//     border-bottom: 1px solid !important;
//     border-left: 1px solid !important;
//     margin-right: 0.1em !important;
//     margin-left: 0.4em !important;
//     transform: rotate(-45deg) !important;
//     border-top: none !important;
//     border-right: none !important;
//     margin-bottom: 2px;
//   }
// }

.dropdown-item.active,
.dropdown-item:active {
  color: $light !important;
  text-decoration: none;
  background-color: rgba($light, 0.1) !important;
}

.nav-link {
  cursor: pointer;
  display: flex !important;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-link-tabs {
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-decoration: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  .num-circle {
    background-color: #2c2c2d !important;
    height: 33px;
    width: 33px;
    color: #bebebf;
  }

  .nav-link-title {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    color: #bebebf;
  }
}

.nav-link-tabs.active {
  .nav-link-title {
    color: #fff;
  }

  .num-circle {
    background-color: #202020 !important;
    color: #fff;
  }
}

.nav-link-tabs-light {
  cursor: pointer;
  padding: 1.2rem 1rem;
  text-decoration: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  .nav-link-title {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    color: #bebebf;
  }
}

.nav-link-tabs-light.active {
  background-color: rgba($color: #f4f6fa, $alpha: 1);

  .nav-link-title {
    color: $secondary;
  }
}

.nav-link-tabs-dark {
  cursor: pointer;
  padding: 1.2rem 1rem;
  text-decoration: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  .nav-link-title {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    color: $secondary;
  }

  &:hover {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    background-color: rgba($color: $primary, $alpha: 0.1);
  }
}

.nav-link-tabs-dark.active {
  background-color: rgba($color: $primary, $alpha: 1) !important;

  .nav-link-title {
    color: $white;
  }
}

.accordion-button.collapsed {
  border-bottom: none !important;
}

.input-group {
  .input-group-text {
    border-left: none !important;
  }
}

.alert-danger {
  color: #842029 !important;
  background-color: #f8d7da !important;
  border-color: #f5c2c7 !important;
  border-left: 0.25rem solid #842029 !important;
}

.alert-warning {
  color: $warning !important;
  background-color: #fff3cd !important;
  border-color: #ffecb5 !important;
  border-left: 0.25rem solid #664d03 !important;
}

.alert-success {
  color: #0f5132 !important;
  background-color: #d1e7dd !important;
  border-color: #badbcc !important;
  border-left: 0.25rem solid #0f5132 !important;
}

.v {
  .accordion-button {
    display: block !important;
    background-color: transparent !important;
    padding: 0px !important;
    border-bottom: none !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, border-radius 0.15s ease !important;

    &:not(.collapsed) {
      font-weight: normal;
      box-shadow: none !important;
      border-bottom: 1px solid #e6e8e9;

      &::after {
        display: none !important;
      }
    }

    &::after {
      display: none !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.b {
  .accordion-button {
    background-color: transparent !important;
    padding: 8px 0px !important;
    border-bottom: none !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, border-radius 0.15s ease !important;

    &:not(.collapsed) {
      font-weight: normal;
      box-shadow: none !important;
      border-bottom: 1px solid #e6e8e9 !important;
    }

    &::after {
      display: inline-block;
      vertical-align: 0.306em;
      width: 1em;
      height: 0.8em;
      background-size: 0.8rem;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.accordion-button {
  background-color: transparent !important;
  padding: 8px 16px !important;
  border-bottom: 1px solid #e6e8e9 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease !important;

  &:not(.collapsed) {
    font-weight: normal;
    box-shadow: none !important;
    border-bottom: 1px solid #e6e8e9;
  }

  &::after {
    display: inline-block;
    vertical-align: 0.306em;
    width: 0.8em;
    height: 0.8em;
    background-size: 0.8rem;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.accordion-item {
  background-color: transparent !important;
  border: none !important;
}

.border-b-1 {
  border-bottom: 1px solid rgba(101, 109, 119, 0.16);
  border-top: 1px solid #fff;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: none;
  }
}

.highcharts-credits {
  display: none;
}

.apexcharts-svg {
  background-color: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.card-loading {
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;
  color: transparent !important;
  text-shadow: none !important;
  pointer-events: none;

  .card-body {
    opacity: 1 !important;
  }

  &:after {
    opacity: 1 !important;
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    border: 1px solid currentColor;
    border-right-color: transparent;
    border-radius: 100rem;
    color: #656d77;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    left: calc(50% - 0.5rem);
    top: calc(50% - 0.5rem);
    animation: spinner-border 0.75s linear infinite;
  }
}

.select__control {
  background-color: #f4f6fa !important;
  border: 1px solid $border-color !important;
  max-height: 30px !important;
  min-height: 30px !important;

  &:hover {
    color: inherit;
    background-color: #f4f6fa;
    border-color: 'unset';
    outline: 0;
    box-shadow: 'unset';
  }

  .css-g1d714-ValueContainer {
    padding: 0 0.45rem !important;
    max-height: 30px !important;
    min-height: 30px !important;
  }
}

.is-invalid__control {
  background-color: #f4f6fa !important;
  border: 1px solid #d63939 !important;
  max-height: 30px !important;
  min-height: 30px !important;

  &:hover {
    color: inherit;
    background-color: #f4f6fa;
    border-color: 'unset';
    outline: 0;
    box-shadow: 'unset';
  }

  .is-invalid__indicator {
    padding: 4px !important;

    svg {
      fill: #d63939 !important;
    }
  }
}

.select__indicator {
  padding: 2px !important;
}

.form-control-select {
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.42857;
  color: #232e3c;
  border: 1px solid #dadcde;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select__control--is-focused {
  color: inherit;
  background-color: #f4f6fa;
  border-color: rgba($primary, 0.5) !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba($primary, 0.25) !important;
}

.css-b8ldur-Input {
  padding: 0 !important;
}

.c-xs-3 {
  margin-top: unset;
}

.right-sidebar {
  box-shadow: inset 0px 0 1.5px 0px rgba(0, 0, 0, 0.5) !important;
}

.row-body {
  align-items: stretch !important;
  height: 100%;
}

.text-ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden;
}

.h-md-50 {
  height: 50% !important;
}

@media only screen and (max-width: 992px) {
  html {
    overflow-y: auto !important;
  }
}

@media only screen and (max-width: 760px) {

  ul.breadcrumbs {
  padding-inline-start: 10px !important;

    li {
      padding-right: 10px !important;
    }
  }


  .toolbar-width {
    width: 100vw !important;
  }

  .position-md-fixed {
    position: unset !important;
  }

  .position-md-absolute {
    position: unset !important;
  }

  .location-container {
    max-height: 100% !important;
    height: auto !important;
    width: 100% !important;
    overflow-x: unset !important;
  }

  .border-sm-none {
    border: none !important;
  }

  .border-sm-end {
    border-right: 1px solid rgba(99, 99, 99, 0.16) !important;
  }

  .sidebar-body {
    max-height: 100% !important;
  }

  .sidebar-body-create {
    height: 100% !important;
  }

  .fullscreen {
    height: 100vh !important;
    width: 100vw !important;
  }

  .el-picker-panel__body {
    display: flex;
    flex-direction: column;
    min-width: auto !important;
  }

  .el-date-range-picker {
    min-width: 100% !important;
    right: 0 !important;
  }

  .el-date-range-picker__content {
    width: 100%;
  }

  .el-picker-panel {
    transform: translate3d(0px, 206px, 0px) !important;
  }

  html {
    overflow-y: auto !important;

    .filter-mobile {
      > div {
        margin-bottom: 10px;

        .select__control {
          background-color: #fff !important;
        }
      }
    }

    .custom-maps {
      max-height: 400px !important;
    }

    .force-top {
      margin-top: 200px;
    }

    .b-xs-3 {
      margin-bottom: 1.5rem;
      padding-left: 16px;
      padding-right: 16px;
    }

    .login {
      min-width: unset !important;
    }

    .img-empty {
      height: auto !important;
      width: 100% !important;
    }

    .b {
      .accordion-button {
        max-height: 36px !important;
      }
    }

    .h-md-50 {
      height: 100% !important;
    }

    .h-sm-25 {
      height: 25% !important;
    }

    .service-title {
      font-size: 6vw;
      margin-top: -35px !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      transform: none !important;
    }

    .w-card {
      width: auto !important;
    }
  }
}

.h-sm-25 {
  height: 100% !important;
}

.list-item {
  font-size: 13px !important;
  border-bottom: 1px solid $border-color;
}

.list-item-inherit {
  font-size: 13px !important;
  border-bottom: 1px solid #636363;
}

.hr-text > *:first-child {
  padding-right: 0 !important;
}

@keyframes spin {
  50% {
    top: 81px;
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes container {
  30% {
    transform: rotate(1deg);
  }

  50% {
    top: 11px;
  }

  70% {
    top: 10px;
    transform: rotate(-1deg);
  }
}

@keyframes fade {
  30% {
    opacity: 0.3;
    left: 7px;
  }

  50% {
    opacity: 0.5;
    left: 6px;
  }

  70% {
    opacity: 0.1;
    left: 4px;
  }

  90% {
    opacity: 0.4;
    left: 2px;
  }
}

@keyframes bg {
  from {
    background-position-x: 0px;
  }

  to {
    background-position-x: -400px;
  }
}

@keyframes updown {
  50% {
    transform: translateY(-20%);
  }

  70% {
    transform: translateY(-10%);
  }
}

@keyframes updown-half {
  50% {
    transform: translateY(-10%);
  }

  70% {
    transform: translateY(-5%);
  }
}

.page-loading {
  height: 100%;
  width: 100%;
  background: #f4f6fa !important;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: visibility 0s, opacity 0.5s linear;

  .truck-wrapper {
    height: 200px;
    width: 200px;
    border: 4px solid #1dd3d6;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.8);
    background: white;
    animation: bg 0.5s linear infinite;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: inset 0px 0px 10px 4px rgba(0, 0, 0, 0.3),
      inset 0px 0px 5px 0px #1dd3d6;
  }

  .truck-wrapper {
    &:after {
      content: 'Loading';
      font-size: 20px;
      position: absolute;
      bottom: 0px;
      text-align: center;
      width: 100%;
      border-top: 1px solid #1dd3d6;
      background: rgb(30, 252, 200);
      background: -moz-linear-gradient(
        left,
        rgba(30, 252, 200, 1) 0%,
        rgba(29, 211, 214, 1) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(30, 252, 200, 1) 0%,
        rgba(29, 211, 214, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(30, 252, 200, 1) 0%,
        rgba(29, 211, 214, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1efcc8', endColorstr='#1dd3d6', GradientType=1);
      color: white;
      padding-top: 8px;
      padding-bottom: 18px;
      animation: bg 3s linear infinite;
    }
  }

  .truck {
    height: 110px;
    width: 150px;
    position: absolute;
    bottom: 48px;
    left: calc(50% + 10px);
    transform: translateX(-50%);

    > .truck-container {
      background: rgb(175, 189, 195);
      background: -moz-linear-gradient(
        -45deg,
        rgba(175, 189, 195, 1) 0%,
        rgba(175, 189, 195, 1) 50%,
        rgba(143, 163, 173, 1) 51%,
        rgba(143, 163, 173, 1) 100%
      );
      background: -webkit-linear-gradient(
        -45deg,
        rgba(175, 189, 195, 1) 0%,
        rgba(175, 189, 195, 1) 50%,
        rgba(143, 163, 173, 1) 51%,
        rgba(143, 163, 173, 1) 100%
      );
      background: linear-gradient(
        135deg,
        rgba(175, 189, 195, 1) 0%,
        rgba(175, 189, 195, 1) 50%,
        rgba(143, 163, 173, 1) 51%,
        rgba(143, 163, 173, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#afbdc3', endColorstr='#8fa3ad', GradientType=1);
      height: 50px;
      width: 80px;
      position: absolute;
      top: 10px;
      left: 10px;
      animation: container 0.4s linear infinite;
    }

    > .glases {
      background: rgb(40, 181, 245);
      background: -moz-linear-gradient(
        -45deg,
        rgba(40, 181, 245, 1) 0%,
        rgba(40, 181, 245, 1) 50%,
        rgba(2, 153, 227, 1) 52%,
        rgba(2, 153, 227, 1) 100%
      );
      background: -webkit-linear-gradient(
        -45deg,
        rgba(40, 181, 245, 1) 0%,
        rgba(40, 181, 245, 1) 50%,
        rgba(2, 153, 227, 1) 52%,
        rgba(2, 153, 227, 1) 100%
      );
      background: linear-gradient(
        135deg,
        rgba(40, 181, 245, 1) 0%,
        rgba(40, 181, 245, 1) 50%,
        rgba(2, 153, 227, 1) 52%,
        rgba(2, 153, 227, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#28b5f5', endColorstr='#0299e3', GradientType=1);
      position: absolute;
      height: 25px;
      width: 25px;
      border: 4px solid #fbd734;
      border-bottom: none;
      top: 35px;
      left: 95px;
      border-top-right-radius: 6px;
      animation: updown-half 0.4s linear infinite;

      &:after {
        content: '';
        display: block;
        background-color: #fbd734;
        height: 13px;
        width: 10px;
        position: absolute;
        right: -10px;
        bottom: 0px;
        border-radius: 10px / 15px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
      }

      &:before {
        content: '';
        display: block;
        background-color: #f9bf2c;
        height: 12px;
        width: 16px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        border-top-right-radius: 4px;
      }
    }

    > .bonet {
      background-color: #f9bf2c;
      position: absolute;
      width: 124px;
      height: 15px;
      top: 64px;
      left: 10px;
      z-index: -1;
      animation: updown 0.4s linear infinite;

      &:after {
        content: '';
        display: block;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
          -45deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(241, 241, 241, 1) 50%,
          rgba(225, 225, 225, 1) 51%,
          rgba(246, 246, 246, 1) 100%
        );
        background: -webkit-linear-gradient(
          -45deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(241, 241, 241, 1) 50%,
          rgba(225, 225, 225, 1) 51%,
          rgba(246, 246, 246, 1) 100%
        );
        background: linear-gradient(
          135deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(241, 241, 241, 1) 50%,
          rgba(225, 225, 225, 1) 51%,
          rgba(246, 246, 246, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f6f6f6', GradientType=1);
        height: 10px;
        width: 6px;
        position: absolute;
        right: 0px;
        bottom: 2px;
        border-top-left-radius: 4px;
      }
    }

    > .base {
      position: absolute;
      background-color: #445a64;
      width: 106px;
      height: 15px;
      border-top-right-radius: 10px;
      top: 70px;
      left: 14px;
      animation: updown 0.4s linear infinite;

      &:before {
        content: '';
        display: block;
        background-color: #e54a18;
        height: 12px;
        width: 5px;
        position: absolute;
        left: -4px;
        bottom: 0px;
        border-bottom-left-radius: 4px;
      }

      &:after {
        content: '';
        display: block;
        background-color: RGB(84, 110, 122);
        height: 10px;
        width: 20px;
        position: absolute;
        right: -16px;
        bottom: 0px;
        border-bottom-right-radius: 4px;
        z-index: -1;
      }
    }

    > .base-aux {
      width: 82px;
      height: 8px;
      background-color: #f9bf2c;
      position: absolute;
      top: 65px;
      left: 14px;
      border-bottom-right-radius: 4px;
      animation: updown 0.4s linear infinite;
    }

    > .wheel-back {
      left: 20px;
      border-radius: 100%;
      position: absolute;
      background: rgb(84, 110, 122);
      background: -moz-linear-gradient(
        -45deg,
        rgba(84, 110, 122, 1) 0%,
        rgba(84, 110, 122, 1) 49%,
        rgba(68, 90, 100, 1) 52%,
        rgba(68, 90, 100, 1) 100%
      );
      background: -webkit-linear-gradient(
        -45deg,
        rgba(84, 110, 122, 1) 0%,
        rgba(84, 110, 122, 1) 49%,
        rgba(68, 90, 100, 1) 52%,
        rgba(68, 90, 100, 1) 100%
      );
      background: linear-gradient(
        135deg,
        rgba(84, 110, 122, 1) 0%,
        rgba(84, 110, 122, 1) 49%,
        rgba(68, 90, 100, 1) 52%,
        rgba(68, 90, 100, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#546e7a', endColorstr='#445a64', GradientType=1);
      top: 80px;
      height: 22px;
      width: 22px;
      animation: spin 0.6s linear infinite;

      &:before {
        content: '';
        border-radius: 100%;
        left: 5px;
        top: 5px;
        position: absolute;
        background: rgb(175, 189, 195);
        background: -moz-linear-gradient(
          -45deg,
          rgba(175, 189, 195, 1) 0%,
          rgba(175, 189, 195, 1) 50%,
          rgba(143, 163, 173, 1) 51%,
          rgba(143, 163, 173, 1) 100%
        );
        background: -webkit-linear-gradient(
          -45deg,
          rgba(175, 189, 195, 1) 0%,
          rgba(175, 189, 195, 1) 50%,
          rgba(143, 163, 173, 1) 51%,
          rgba(143, 163, 173, 1) 100%
        );
        background: linear-gradient(
          135deg,
          rgba(175, 189, 195, 1) 0%,
          rgba(175, 189, 195, 1) 50%,
          rgba(143, 163, 173, 1) 51%,
          rgba(143, 163, 173, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#afbdc3', endColorstr='#8fa3ad', GradientType=1);
        height: 12px;
        width: 12px;
      }
    }

    > .wheel-front {
      left: 95px;
      border-radius: 100%;
      position: absolute;
      background: rgb(84, 110, 122);
      background: -moz-linear-gradient(
        -45deg,
        rgba(84, 110, 122, 1) 0%,
        rgba(84, 110, 122, 1) 49%,
        rgba(68, 90, 100, 1) 52%,
        rgba(68, 90, 100, 1) 100%
      );
      background: -webkit-linear-gradient(
        -45deg,
        rgba(84, 110, 122, 1) 0%,
        rgba(84, 110, 122, 1) 49%,
        rgba(68, 90, 100, 1) 52%,
        rgba(68, 90, 100, 1) 100%
      );
      background: linear-gradient(
        135deg,
        rgba(84, 110, 122, 1) 0%,
        rgba(84, 110, 122, 1) 49%,
        rgba(68, 90, 100, 1) 52%,
        rgba(68, 90, 100, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#546e7a', endColorstr='#445a64', GradientType=1);
      top: 80px;
      height: 22px;
      width: 22px;
      animation: spin 0.6s linear infinite;

      &:before {
        content: '';
        border-radius: 100%;
        left: 5px;
        top: 5px;
        position: absolute;
        background: rgb(175, 189, 195);
        background: -moz-linear-gradient(
          -45deg,
          rgba(175, 189, 195, 1) 0%,
          rgba(175, 189, 195, 1) 50%,
          rgba(143, 163, 173, 1) 51%,
          rgba(143, 163, 173, 1) 100%
        );
        background: -webkit-linear-gradient(
          -45deg,
          rgba(175, 189, 195, 1) 0%,
          rgba(175, 189, 195, 1) 50%,
          rgba(143, 163, 173, 1) 51%,
          rgba(143, 163, 173, 1) 100%
        );
        background: linear-gradient(
          135deg,
          rgba(175, 189, 195, 1) 0%,
          rgba(175, 189, 195, 1) 50%,
          rgba(143, 163, 173, 1) 51%,
          rgba(143, 163, 173, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#afbdc3', endColorstr='#8fa3ad', GradientType=1);
        height: 12px;
        width: 12px;
      }
    }

    > .smoke {
      position: absolute;
      background-color: #afbdc3;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      top: 90px;
      left: 6px;
      animation: fade 0.4s linear infinite;
      opacity: 0;

      &:after {
        content: '';
        position: absolute;
        background-color: RGB(143, 163, 173);
        border-radius: 100%;
        width: 6px;
        height: 6px;
        top: -4px;
        left: 4px;
      }

      &:before {
        content: '';
        position: absolute;
        background-color: RGB(143, 163, 173);
        border-radius: 100%;
        width: 4px;
        height: 4px;
        top: -2px;
        left: 0px;
      }
    }
  }
}

.signin {
  background-color: $secondary;
  vertical-align: middle;
  justify-content: center;
  background-image: url('../assets/logo_jpl1.png');
  background-size: 80%;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.login {
  min-width: 450px;
}

.login-form {
  .form-control {
    padding-left: 3rem !important;

    &:focus {
      background-color: #f4f6fa;
      border-left: 4px solid $primary;
      outline: 0;
      box-shadow: none;
    }
  }

  .invalid-feedback {
    display: unset !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 85.71429%;
    color: #d63939;
  }
}

.th {
  background: #f4f6fa;
  font-size: 0.625rem !important;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  line-height: 1.6;
  color: #636363;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;

  td {
    font-size: 0.625rem !important;
  }
}

.border-dashed {
  border: 2px dashed $secondary !important;
}

.nav-link.active {
  color: #ffffff;
  background-color: rgba(244, 246, 250, 0.06);
}

.nav-link.actives {
  color: $white !important;
  background-color: rgba($color: $primary, $alpha: 1);
  font-weight: 500;
}

.dx-card-title {
  text-transform: uppercase !important;
  font-weight: 700;
  font-size: 13px;
  color: #343a40 !important;
  letter-spacing: 0.5px !important;
}

.hint {
  font-size: 75%;
  font-weight: 400;
  display: block;
}

.btn-action {
  min-width: 100%;
}

.btn-action-close {
  border: none !important;
  padding: 5px !important;
}

@media only screen and (min-width: 500px) {
  .btn-action {
    min-width: 250px;
  }
}

.bg-primary-lt {
  background: rgba($primary, 0.1) !important;
  color: $primary;
}

.bg-success-lt {
  background: rgba($success, 0.1) !important;
  color: $success;
}

.bg-warning-lt {
  background: rgba($warning, 0.1) !important;
  color: $warning;
}

.bg-danger-lt {
  background: rgba($danger, 0.1) !important;
  color: $danger;
}

.bg-purple-lt {
  background: rgba($purple, 0.1) !important;
  color: $purple;
}

.bg-secondary-lt {
  background: rgba($secondary, 0.1) !important;
  color: $secondary;
}

.bg-gray-lt {
  background: rgba($cyan-100, 0.9) !important;
}

.text-gray {
  color: $cyan;
}

.text-grey {
  color: $grey;
}

.bg-pink-lt {
  background: rgba($pink, 0.1) !important;
}

.bg-light-lt {
  background: rgba(255, 255, 255, 0.9) !important;
}

.bg-light-lt-x {
  background: rgba(255, 255, 255, 0.8) !important;
}

.history {
  height: 100% !important;

  tbody {
    display: block;
    max-height: auto !important;
    overflow-y: auto;
    min-height: auto !important;
    padding-bottom: 0 !important;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid rgba(99, 99, 99, 0.16) !important;
    min-height: 40px;

    &:last-child {
      border-bottom: 0px !important;
    }

    &:hover {
      cursor: pointer;
      background: #f4f6fa;
      transition-property: background;
      transition-duration: 0.2s;
      transition-timing-function: linear;
      color: inherit;
    }
  }
}

.chat {
  .chat-body {
    overflow-x: hidden !important;
    overflow-y: overlay !important;
    min-height: 300px;
    max-height: 300px;
    height: 300px;
  }

  .chat-footer {
    border-top: 1px solid $border-color;
    min-height: 35px;
  }

  .chat-input {
    &:focus {
      box-shadow: none !important;
    }
  }

  .chat-btn {
    &:focus {
      box-shadow: none !important;
    }
  }
}

.log-container {
  overflow-x: hidden !important;
  overflow-y: overlay !important;
  min-height: 350px;
  max-height: 350px;
  height: 350px;
  border: 1px solid $border-color;
  border-radius: 5px;
  // padding: 5px 10px;
}

.text-italic {
  font-style: italic;
}

.cursor-pointer {
  cursor: pointer !important;
}

.service-title {
  padding: 0px 20px;
  background-color: #202020;
  font-size: 2vw;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3px;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
}

.grayscale {
  filter: grayscale(100%);
  cursor: pointer;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  overflow: hidden;

  &:hover {
    filter: none;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

div.animated-img {
  position: relative;
  overflow: hidden;
  min-width: 220px;
  max-width: 100%;
  width: 100%;
  max-height: 375px;
  min-height: 200px;
  height: 270px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  background-color: #000000;

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.55s ease;
    transition: all 0.55s ease;

    &:before,
    &:after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.55s ease;
      transition: all 0.55s ease;
    }
  }

  img {
    max-width: 100%;
    backface-visibility: hidden;
    vertical-align: center;
    opacity: 0.9;
    filter: grayscale(100%);
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    overflow: hidden;
  }

  .title {
    position: absolute;
    top: 68%;
    left: 25px;
    padding: 10px;

    &:before,
    &:after {
      height: 2px;
      width: 450px;
      position: absolute;
      content: '';
      background-color: #ffffff;
    }

    &:before {
      top: 0;
      left: 10px;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }

    &:after {
      bottom: 0;
      right: 10px;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }

    div {
      &:before,
      &:after {
        width: 2px;
        height: 400px;
        position: absolute;
        content: '';
        background-color: #ffffff;
      }

      &:before {
        top: 10px;
        right: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
      }

      &:after {
        bottom: 10px;
        left: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
      }
    }
  }

  h2,
  h4 {
    margin: 0;
    text-transform: uppercase;
  }

  h2 {
    font-weight: 400;
    display: block;
    font-weight: 700;
    background-color: #ffffff;
    padding: 5px 10px;
    color: #000000;
  }

  h4 {
    display: block;
    font-weight: 700;
    padding: 5px 10px;
    color: #000000;
    text-decoration: underline;
    text-decoration-color: #ffffff;
    text-decoration-thickness: 2px;
  }

  span {
    position: absolute;
    bottom: 42%;
    left: 25px;
    text-align: left;
    opacity: 0;
    padding: 5px 10px;
    font-size: 0.8em;
    font-weight: 500;
    letter-spacing: 1.5px;
    min-width: 260px;
  }

  &:hover img {
    zoom: 1;
    filter: alpha(opacity=35);
    -webkit-opacity: 0.35;
    opacity: 0.35;
    filter: none;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  &:hover .title:before,
  &:hover .title:after,
  &:hover .title div:before,
  &:hover .title div:after {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  &:hover .title:before,
  &:hover .title:after {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
  }

  &:hover span {
    opacity: 1;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    min-width: 260px;
  }
}

.padding-20 {
  padding: 20px;
}

.padding-big {
  margin-bottom: 100px;
}

.map-container {
  height: 100%;
  min-height: 400px;
  width: auto;
}

.map-container-dashboard {
  height: 100%;
  min-height: 460px;
  width: auto;
}

.map-container-order {
  height: 100%;
  min-height: 200px;
  width: auto;
}

.map-container-vertical {
  min-height: 100%;
  min-height: 500px;
  width: auto;

  .mapboxgl-canvas {
    width: 100% !important;
  }
}

.map-container-small {
  height: 100%;
  min-height: 500px;
  width: auto;
}

.h-map {
  min-height: 250px;
  height: 100%;
}

.map-container-manifest {
  height: 100%;
  min-height: 400px;
  width: auto;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none;
}

.filter {
  span {
    div.input-group {
      background-color: #fff !important;

      input.form-control {
        background-color: #fff !important;
      }

      div.input-group-text {
        background-color: #fff !important;
      }
    }
  }

  .css-2b097c-container {
    background-color: #fff !important;
    min-width: 180px !important;

    .select__control {
      background-color: #fff !important;
    }
  }
}

.css-1hwfws3 {
  padding: 0px 8px !important;
}

.mapboxgl-popup-close-button {
  display: none !important;
}

.pdf-modals {
  .modal-content {
    background-color: transparent !important;
  }
}
// filter coba coba

.filter-overlay {
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 2000;
}

.filter-modal {
  position: fixed;
  right: -150vh;
  background-color: #fff;
  width: 80vw;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  top: 0;
  bottom: 0;
  padding: 0 12px 12px;
  transition: all 0.3s ease-out;
  z-index: 2010;
}

.filter-modal.filter-show {
  right: 0;
}

.filter-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 10px;
  background-color: transparent;
  border: 0;
  font-size: 18px;
}

.service-radio-image {
  display: inline-block;

  .overlay {
    background-color: rgba($color: $light, $alpha: 0.8);
    cursor: pointer;
    z-index: 1000;
  }

  > input {
    display: none;
  }

  img {
    height: 50px;
    width: 50px;
  }
}

.service-radio-image-hover {
  &:hover {
    opacity: 1;
    background-color: rgba($color: $light, $alpha: 0.8);
    border-color: $primary !important;
  }
}

.select__menu-list {
  div {
    white-space: pre-wrap !important;
  }
}

.css-9gakcf-option {
  background: unset !important;
  color: #202020 !important;
  cursor: pointer;

  &:hover {
    background: #deebff !important;
  }
}

// status //
.status-new-lt,
.status-draft-lt {
  background: rgba($primary, 0.1) !important;
  color: $primary;
  min-width: 100px !important;
}

.status-process-lt,
.status-published-lt,
.status-approved-lt,
.status-manifested-lt {
  background: rgba($warning, 0.1) !important;
  color: $warning;
  min-width: 100px !important;
}

.status-on_pickup-lt,
.status-started-lt,
.status-departed-lt,
.status-on_transit-lt,
.status-forwarder-lt {
  background: rgba($yellow, 1) !important;
  color: $white;
  min-width: 100px !important;
}

.status-on_delivery-lt,
.status-pickup-lt {
  background: rgba($warning, 1) !important;
  color: $white;
  min-width: 100px !important;
}

.status-console-lt,
.status-on_progress-lt,
.status-transit-lt {
  background: rgba($primary, 1) !important;
  color: $white;
  min-width: 100px !important;
}

.status-unreceived-lt,
.status-danger-lt,
.status-disputed-lt {
  background: rgba($danger, 1) !important;
  color: $white;
  min-width: 100px !important;
}

.status-received-lt,
.status-delivered-lt,
.status-published-lt,
.status-arrived-lt,
.status-completed-lt,
.status-last_mile-lt {
  background: rgba($success, 1) !important;
  color: $white;
  min-width: 100px !important;
}

.status-voided-lt,
.status-draft-lt,
.status-void-lt {
  background: rgba($grey, 0.1) !important;
  color: $grey;
  min-width: 100px !important;
}

.status-paided-lt,
.status-paid-lt,
.status-partial_paid-lt {
  background: rgba($primary, 1) !important;
  color: $white;
  min-width: 100px !important;
}

.log-timeline {
  margin: auto;
  display: block;
  position: relative;

  ul.log {
    margin: 10px;
    padding: 0;
    display: block;

    li {
      list-style: none;
      margin-left: 1rem;
      min-height: 40px;
      border-left: 1px dashed $grey;
      padding: 0 0 20px 20px;
      position: relative;
      font-size: 11px;
      left: -10px;

      &:first-child {
        color: $primary !important;
        border-left: 1px dashed $primary;

        .log-timestamp {
          color: $primary;
        }
      }

      &:last-child {
        border-left: 0;
      }

      &::before {
        position: absolute;
        left: -5px;
        top: 7px;
        content: ' ';
        outline: thick solid rgba($color: $grey, $alpha: 0.3);
        border-radius: 100%;
        height: 10px;
        background: rgba($grey, 1);
        width: 10px;
        transition: all 500ms ease-in-out;
      }
    }

    li:first-child:before {
      background: $primary;
      outline: thick solid rgba($color: $primary, $alpha: 0.3);
    }
  }
}

ul.log li .log-timestamp {
  color: $secondary;
  font-size: 13px;
  font-weight: 600;
}

.pdf-wrapper {
  margin: 0 auto;
  width: 155mm;
  height: 220mm;
  position: relative;
}

.pdf-sheet {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
  width: 155mm;
  height: 220mm;
  background: #fff;
  box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
  margin: 5mm auto;
}

.pdf-title {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}

.pdf-text {
  font-size: 9px;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}

.pdf-subtitle {
  font-size: 8px;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}

.pdf-head {
  font-size: 9px;
  font-weight: 700;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}

.pdf-table-header {
  padding: 5px;
}

.pdf-border {
  border-collapse: collapse !important;
  border-width: 0.5px !important;
}

.zIndex-upset {
  z-index: 99999;
}

.search-initials {
  max-height: 400px;
  overflow-y: scroll;

  .search-list {
    &:hover {
      background-color: rgba($color: $primary, $alpha: 0.3);
    }
  }
}

.search-initial-modals {
  max-height: 200px;
  overflow-y: scroll;

  .search-list {
    &:hover {
      background-color: rgba($color: $primary, $alpha: 0.3);
    }
  }
}

.position-md-fixed {
  position: fixed;
}

.position-md-absolute {
  position: absolute;
}

.desctiption-select-span {
  display: block;
  color: #636363;
  font-size: 0.75rem;
  padding-left: 2.2em !important;
  margin-top: -0.25rem !important;
}

.desctiption-checkbox {
  display: block;
  font-weight: normal !important;
  color: #636363;
  font-size: 0.75rem;
}

.cp-enabled {
  cursor: pointer !important;
}

.controls-img-editor {
  .slider {
    padding: 22px 0px;
  }

  .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    background: $primary;
    width: 100%;

    &::-moz-range-thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid #fff;
      background: $primary;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid #fff;
      background: $primary;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  &:hover input[type='range'] {
    &::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    }
  }
}

.border-top-md-0 {
  border: none;
}

@keyframes motion {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes dynamic {
  0% {
    transform: translateX(-40px);
  }

  25% {
    transform: translateX(-80px);
  }

  50% {
    transform: translateX(-120px);
  }

  75% {
    transform: translateX(-160px);
  }

  100% {
    transform: translateX(-200px);
  }
}

.text-load {
  position: absolute;
  bottom: -8px;
  left: -85px;
  margin-bottom: 0px !important;
}

.respon-loader {
  width: 80px;
  height: 67px;
  margin: auto;
  position: relative;

  .square {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    animation-fill-mode: both;
    animation: motion 5s infinite cubic-bezier(0.89, 0.03, 0.06, 1.5);
  }

  .path {
    height: 10px;
    width: 130px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;

    > div {
      background: #444;
      width: 10px;
      height: 10px;
      border-radius: 2px;
      margin: 0 15px;
      position: absolute;
      top: 0;
      left: 0;
      animation-fill-mode: both;
      animation: dynamic 5s infinite cubic-bezier(0.89, 0.03, 0.06, 1.5);

      &:nth-child(1) {
        left: 40px;
      }

      &:nth-child(2) {
        left: 80px;
      }

      &:nth-child(3) {
        left: 120px;
      }

      &:nth-child(4) {
        left: 160px;
      }

      &:nth-child(5) {
        left: 200px;
      }

      &:nth-child(6) {
        left: 240px;
      }

      &:nth-child(7) {
        left: 280px;
      }
    }
  }
}

.cursor-disable {
  cursor: not-allowed;
}

.sidebar-body {
  max-height: calc(100vh - 30vh);
  overflow-y: auto !important;
  overflow-x: hidden !important;
  scroll-behavior: smooth !important;
}

.sidebar-body-create {
  height: calc(100vh - 21vh);
  overflow-y: auto !important;
  overflow-x: hidden !important;
  scroll-behavior: smooth !important;
}

.uploader {
  height: 100px;
  width: 100px;
  background-color: #ececec;
  border-radius: 10px;

  &:hover {
    border: 1px solid rgba($color: $primary, $alpha: 0.6);
  }

  &.small {
    height: 50px !important;
    width: 50px !important;
  }

  &.medium {
    height: 80px !important;
    width: 80px !important;
  }
}

// ini untuk image-hover
.img-hover-sorting {
  cursor: pointer;
  object-fit: cover;
  transition: all 0.4s ease-in-out;

  &:hover {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
    border-radius: unset !important;
    border: none !important;
  }
}

.img-hover {
  width: 100px;
  height: 100px;
  cursor: pointer;
  object-fit: cover;
  transition: all 0.4s ease-in-out;

  &:hover {
    -ms-transform: scale(1.3);
    /* IE 9 */
    -webkit-transform: scale(1.3);
    /* Safari 3-8 */
    transform: scale(1.3);
    border-radius: unset !important;
    border: none !important;
  }

  &.small {
    height: 50px !important;
    width: 50px !important;

    &.hover {
      height: 100% !important;
      width: 100% !important;
      -ms-transform: scale(1.3);
      /* IE 9 */
      -webkit-transform: scale(1.3);
      /* Safari 3-8 */
      transform: scale(1.3);
      border-radius: unset !important;
      border: none !important;
    }
  }

  &.medium {
    height: 80px !important;
    width: 80px !important;

    &.hover {
      height: 100% !important;
      width: 100% !important;
      -ms-transform: scale(1.3);
      /* IE 9 */
      -webkit-transform: scale(1.3);
      /* Safari 3-8 */
      transform: scale(1.3);
      border-radius: unset !important;
      border: none !important;
    }
  }
}

.ribbon {
  width: 80px;
  height: 80px;
  display: block;
  background-color: transparent !important;
  position: relative;
  overflow: hidden;

  span {
    width: 100px;
    height: 30px;
    position: absolute;
    display: block;
    background: $secondary;
    color: white;
    text-align: center;
    line-height: 30px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }
}

// maps
.map-location {
  min-height: 400px;
  display: block;

  .gmnoprint {
    display: none;
  }

  .gm-fullscreen-control {
    display: none;
  }

  .markerfixed {
    left: 50%;
    margin-left: -18px;
    margin-top: -36px;
    position: absolute;
    top: 50%;
    z-index: 998;
  }

  .reCenter {
    cursor: pointer;
    right: 10px;
    position: absolute;
    bottom: 10px;
    background-color: #fff;
    padding: 2px;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.47);
    box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.47);
  }
}

.mapWrapper {
  margin-top: 50px;
  height: 100%;

  .map {
    width: 100%;
    height: 300px;
    max-width: 500px;

    .gmnoprint {
      display: none;
    }

    .gm-fullscreen-control {
      display: none;
    }
  }

  .note {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section-title {
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  line-height: 1.6;
  color: #636363;
}

.nav-item.nav-link.active span {
  /* hot pink, bold text for active tab title */
  color: #ff3399;
  font-weight: bolder;
}

.tabs {
  .active {
    background-color: rgba($color: $dark, $alpha: 0.6) !important;
    color: #fff !important;
  }
}

.form-control {
  padding: 0.25rem 0.45rem;
}

.map-address {
  background-color: rgba($color: $secondary, $alpha: 0.6);
  width: 100%;
  max-height: 100px;

  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.legs {
  height: auto;

  .leg-list {
    display: flex;
    flex-direction: column;
    max-height: 700px;
    overflow-x: hidden !important;
    overflow-y: auto !important;

    div {
      flex-shrink: 0;
      overflow: auto;
    }
  }

  .leg-detail-container {
    height: 700px;

    .full-container {
      max-height: 680px;
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }

    .top-container {
      max-height: 300px;
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }

    .map-container-vertical {
      height: 700px !important;
    }
  }
}

.feature-listing {
  max-height: 500px;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.css-26l3qy-menu {
  z-index: 10 !important;
}

.css-1uccc91-singleValue {
  top: 45% !important;
}

.invalid-feedback {
  font-size: 0.625rem !important;
}

.location-container {
  max-height: 500px;
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

.location-card {
  &:hover {
    background-color: rgba($color: $light, $alpha: 0.5);
  }
}

.table-col {
  .fs-8 {
    font-size: 13px !important;
  }

  small {
    font-size: 9px;
    letter-spacing: 0.5px;

    &.info {
      display: block;
    }
  }
}

// monitoring
.manifest-monitoring {
  .sidebar {
    height: calc(100vh - 60px) !important;

    overflow-y: auto !important;
    padding-bottom: 20px;
  }

  .body-container {
    height: calc(100vh - 60px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    padding-bottom: 20px;

    .map-manifest-monitoring {
      height: 100%;
      min-height: 50vh;
      width: auto;
    }
  }
}

@keyframes pulse-primary {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color: $primary, $alpha: 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($color: $primary, $alpha: 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color: $primary, $alpha: 0);
  }
}

.blob {
  background: rgba($color: $primary, $alpha: 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba($color: $primary, $alpha: 1);
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-primary 2s infinite;
}

.bg-hover {
  &:hover {
    background: rgba($color: $light, $alpha: 0.9);
  }
}

.tooltip-content {
  min-width: 290px !important;
  max-width: 300px !important;
  text-align: left !important;
  text-transform: uppercase;
  font-size: 9px !important;
  word-wrap: break-word;
  padding-right: 90px;

  p {
    word-wrap: break-word;
  }
}

.mapboxgl-popup {
  .mapboxgl-popup-content {
    background: rgba($color: $dark, $alpha: 0.9) !important;
    color: rgba($color: $light, $alpha: 0.7) !important;
    font-size: 10px !important;
    line-height: 11px !important;
    text-transform: uppercase;
    // text-shadow: 1px 1px #000000;

    p {
      margin: 5px 0;
      line-height: 11px;
    }

    small {
      display: block;
      font-size: 10px !important;
    }
  }
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: rgba($color: $dark, $alpha: 0.9) !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: rgba($color: $dark, $alpha: 0.9) !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: rgba($color: $dark, $alpha: 0.9) !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: rgba($color: $dark, $alpha: 0.9) !important;
}

.mapboxgl-nvo-marker {
  width: 0;
  height: 0;

  b {
    transform: rotateZ(135deg);
  }
}

@keyframes pulse-marker {
  0% {
    transform: scale(0.95);
    transform: rotateZ(-135deg);
    box-shadow: 0 0 0 0 rgba($color: $primary, $alpha: 0.7);
  }

  70% {
    transform: scale(1);
    transform: rotateZ(-135deg);
    box-shadow: 0 0 0 10px rgba($color: $primary, $alpha: 0);
  }

  100% {
    transform: scale(0.95);
    transform: rotateZ(-135deg);
    box-shadow: 0 0 0 0 rgba($color: $primary, $alpha: 0);
  }
}

.mapboxgl-nvo-point {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  color: #fff;
  border: solid 2px;
  border-radius: 0 70% 70%;
  box-shadow: 0 0 2px #000;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
  font-size: 9px !important;
  text-align: center;

  &.active {
    animation: pulse-marker 2s infinite;
  }

  &.drop-point {
    background-color: $success !important;
  }

  &.pickup-point {
    background-color: rgba($color: $primary, $alpha: 0.9) !important;
  }
}

.suggestion-container {
  position: relative;
  box-sizing: border-box;

  .suggestion-box {
    top: 100%;
    border-radius: 4px;
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    z-index: 999 !important;
    box-shadow: $box-shadow;
    border: 1px solid $border-color;
    background: $white;

    .suggestion-list {
      max-height: 300px;
      overflow-y: auto;
      padding-bottom: 4px;
      padding-top: 4px;
      position: relative;
      -webkit-overflow-scrolling: touch;
      box-sizing: border-box;

      div {
        white-space: pre-wrap !important;
      }

      .suggestion-option {
        padding: 8px 12px;
        width: 100%;
      }
    }
  }
}

.map-address {
  z-index: 99 !important;
  background: rgba($color: $dark, $alpha: 0.9) !important;
  color: #fff;
  padding: 15px 20px;
  position: absolute;
  bottom: 0;

  .btn {
    margin-top: 0px;
    position: absolute;
    right: 20px;
    top: 15px;
  }

  h3 {
    font-size: 11px;
  }

  p {
    font-size: 11px;
    line-height: 15px;
  }
}

.select__multi-value__remove {
}

.select__value-container--is-multi {
  max-height: 10px !important;
  overflow: hidden;

  .select__multi-value__label {
    padding: 0 !important;
    padding-left: 5px !important;
  }
}

@-webkit-keyframes status-pulsate-tertiary {
  25% {
    transform: scale(1, 1);
  }
  80% {
    transform: scale(3, 3);
    opacity: 0;
  }
  100% {
    transform: scale(3, 3);
    opacity: 0;
  }
}
@keyframes status-pulsate-tertiary {
  25% {
    transform: scale(1, 1);
  }
  80% {
    transform: scale(3, 3);
    opacity: 0;
  }
  100% {
    transform: scale(3, 3);
    opacity: 0;
  }
}

.notify {
  h5 {
    margin: 0;
    font-size: 12px;
  }

  p {
    margin: 0;
    font-size: 10px;
  }
}

.notification {
  border: none;
  width: 300px;
  cursor: pointer;

  .list-unreaded {
    background: #f4f6fa !important;

    .text-title {
      font-weight: bold;
    }
  }

  .text-title {
    font-weight: normal;
    font-size: 11px;
    line-height: 22px;
    margin: 0;
  }

  .text-body {
    line-height: 14px;
    font-size: 10px;
  }

  .status-dot {
    --tblr-status-dot-color: var(--tblr-status-color, #616876);
    --tblr-status-size: 0.5rem;
    position: relative;
    display: inline-block;
    width: var(--tblr-status-size);
    height: var(--tblr-status-size);
    background: var(--tblr-status-dot-color);
    border-radius: 100rem;
  }

  .status-unreaded:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 0;
    background: inherit;
    border-radius: inherit;
    opacity: 0.6;
    -webkit-animation: 1s linear 2s backwards infinite status-pulsate-tertiary;
    animation: 1s linear 2s backwards infinite status-pulsate-tertiary;
  }
}

.map-containers {
  position: relative;
  overflow: hidden;
}

@keyframes slide-in {
  100% {
    left: 0;
  }
}

@keyframes slide-out {
  100% {
    left: -250px;
  }
}

.map-container-info {
  z-index: 9999;
  width: 250px;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: block;
  position: absolute;
  top: 0;
  text-align: center;
  padding: 20px;

  &.slide-in {
    transition: .5s;
    left: 0;
   }
   &.slide-out {
    transition: .5s;
    left: -250px;
   }

  h1 {
    font-size: 22px;
    border-bottom: 0.5px solid #333;
    padding-bottom: 10px;
    font-weight: bold;

    span {
      display: block;
      font-size: 10px;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      span {
        font-weight: bold;
        display: block;
        font-size: 28px;
      }
    }
  }

  h2 {
    text-align: center;
    border-radius: 50%;
    width: 170px;
    height: 170px;
    margin: 20px auto 20px;
    display: block;
    // padding-top: 27px;
    font-size: 35px;

    &.success {
      display: flex;
      justify-content: center;
      align-items: center;
      background: green;
      color: #fff;
      border: 10px solid #0f5132;
    }
  }

  .btn {
    margin-top: 20px;
    display: block;
  }
}

.recap-sum {
  color: #fff;
  border: 1px solid #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 20px;

  h3 {
    margin: 0;
  }

  h1 {
    color: #fff;
    margin: 0;

    &.percentage {
      font-size: 92px;
      font-weight: bold;
      text-align: center;
      letter-spacing: -10px;
      line-height: 60px;
      padding-bottom: 0;
      margin-bottom: 10px;

      span {
        font-size: 45px;
        display: inline;
        font-weight: bold;
        margin-left: 5px;
      }
    }

    font-weight: bold;
    letter-spacing: -1px;
    font-size: 34px;

    span {
      font-size: 25px;
      display: inline;
      font-weight: bold;
    }
  }
}

.recap-summary {
  color: #fff;
  padding: 0;

  h3 {
    display: block;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1px;
  }

  h1 {
    font-size: 52px;
    padding: 0;
    margin: 0;
    font-weight: bold;
    letter-spacing: -2px;

    span {
      font-size: 28px;
      font-weight: normal;
      letter-spacing: 1px;
      padding-left: 5px;
    }

    &.percentage {
      font-size: 82px;
      line-height: 130px;
      font-weight: bold;
      text-align: center;
      letter-spacing: -10px;

      span {
        font-size: 45px;
        display: inline;
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }


}

.recap-region {
  border: 0.1px solid #fff;
  color: #fff;
  padding: 5px 10px;

  h3 {
    display: block;
    font-size: 13px;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin: 0 0 0px;
  }

  h1 {
    line-height: 24px;
    font-size: 28px;
    margin: 0;
  }

  span {
    padding-left: 0px;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
  }

  small {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0px;
    display: block;
  }
}

#form-file-upload {
  height: 16rem;
  width: 20rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.percentage {
  font-size: 82px;
  line-height: 130px;
  font-weight: bold;
  text-align: center;
  letter-spacing: -10px;
}

ul.breadcrumbs {
  list-style-type: none;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 5px;
  padding-left: 0px !important;

  li {
    padding-right: 5px;
    list-style: '/  ';

    &:first-child {
      list-style: none;
    }
  }
}


.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}


.rtt-card {
  background-color: rgba(245, 245, 245, 0.96);
}

.rtt-label {
  font-family: "Inter";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  color: #636363;
}

.slick-dots {
    position: absolute;
    bottom: -25px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      width: 0.5rem !important;
      height: 0.5rem !important;

      &.slick-active {
        color: $primary !important;
        opacity: 1 !important;
      }

      button {
        width: 0.5rem !important;
        height: 0.5rem !important;

        &::before {
          font-family: 'slick';
          font-size: 0.5rem !important;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 0.5rem !important;
          height: 0.5rem !important;
          content: '•';
          text-align: center;
          opacity: 1;
          color: #D2D2D2 !important;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
}

.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: $primary !important;
}

.container-progress {
  width: 100%;
  text-align: center;
}

.progress2 {
  padding: 0px !important;
  border-radius: 30px;
  background: #E7E9ED;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
  height: 10px;
  border-radius: 30px;
  background-image:
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  // width: 85%;
  // background-color: #EF476F;
  animation: progressAnimation 6s;
}

@keyframes progressAnimation {
  0%   { width: 5%; background-color: rgba(47,179,68,.1);}
  100% { width: 100%; background-color: rgba(47,179,68,1); }
}