//
// Base styles
//

.datagrid {
    // display: grid;
    // grid-gap: var($datagrid-padding);
    // grid-template-columns: repeat(auto-fit, minmax(var($datagrid-item-width), 1fr));

    --tblr-datagrid-padding: 1rem;
    // --tblr-datagrid-item-width: 15rem;
    display: grid;
    grid-gap: var(--tblr-datagrid-padding);
    grid-template-columns: repeat(auto-fit, minmax(var(--tblr-datagrid-item-width), 1fr));
}

.datagrid-title {
    @include subheader;
    margin-bottom: .25rem;
}