.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $body-color;
  background-color: $white;
  border-color: $border-color-dark-transparent;
  white-space: nowrap;

  &:hover {
    color: $body-color;
    border-color: $text-muted-light;
    background-color: $light;
  }

  &:focus {
    border-color: rgba(var(--#{$variable-prefix}btn-color, hex-to-rgb($primary)),
      0.5);
    box-shadow: 0 0 0 $input-btn-focus-width rgba(var(--#{$variable-prefix}btn-color, hex-to-rgb($primary)),
      $input-btn-focus-color-opacity );
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }

  .icon {
    width: $icon-size;
    height: $icon-size;
    min-width: $icon-size;
    margin: 0 0.5rem 0 -0.25rem;
    vertical-align: bottom;
    color: $text-muted;
  }

  .avatar {
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: text-top;
    margin: 0 0.5rem 0 -0.25rem;
  }

  .icon-right {
    margin: 0 -0.25rem 0 0.5rem;
  }

  .badge {
    top: auto;
  }
}

%btn-color {
  background-color: rgba(var(--#{$variable-prefix}btn-color), 1);
  color: var(--#{$variable-prefix}btn-color-text);

  .icon {
    color: inherit;
  }

  &:hover {
    color: var(--#{$variable-prefix}btn-color-text);
    background: rgba(var(--#{$variable-prefix}btn-color-darker), 1);
    border-color: transparent;
  }
}

%btn-outline {
  background-color: transparent;
  color: rgba(var(--#{$variable-prefix}btn-color), 1);
  border-color: $border-color-transparent;

  .icon {
    color: inherit;
  }

  &:hover {
    background-color: rgba(var(--#{$variable-prefix}btn-color), 1);
    color: var(--#{$variable-prefix}btn-color-text);
    border-color: rgba(var(--#{$variable-prefix}btn-color), 1);
  }
}

%btn-ghost {
  background: transparent;
  color: rgba(var(--#{$variable-prefix}btn-color), 1);
  border-color: transparent;

  &:hover {
    background-color: rgba(var(--#{$variable-prefix}btn-color), 1);
    color: var(--#{$variable-prefix}btn-color-text);
    border-color: rgba(var(--#{$variable-prefix}btn-color), 1);
  }
}

.btn-link {
  color: $link-color;
  background-color: transparent;
  border-color: transparent;

  .icon {
    color: inherit;
  }

  &:hover {
    color: $link-hover-color;
    border-color: transparent;
  }
}

.btn-lg {
  svg.icon {
    stroke-width: 2;
  }
}

.btn-pill {
  padding-right: 1.5em;
  padding-left: 1.5em;
  border-radius: 10rem;

  &[class*='btn-icon'] {
    padding: 0.375rem 15px;
  }
}

.btn-icon {
  min-width: add($btn-line-height * $btn-font-size + $btn-padding-y * 2,
      $btn-border-width * 2);

  &.btn-sm {
    min-width: add($btn-line-height * $btn-font-size-sm + $btn-padding-y-sm * 2,
        $btn-border-width * 2);
  }

  .icon {
    margin: 0 -1em;
  }
}

.btn-square {
  border-radius: 0;
}

.btn-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -0.5rem !important;
  margin-right: -0.5rem;

  >* {
    margin: 0 0.5rem 0.5rem 0 !important;
  }
}

.btn-floating {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  border-radius: $border-radius-pill;
}

.btn-loading {
  position: relative;
  color: transparent !important;
  text-shadow: none !important;
  pointer-events: none;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    border: $spinner-border-width-sm solid currentColor;
    border-right-color: transparent;
    border-radius: $border-radius-pill;
    color: $white;
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: calc(50% - 0.5rem);
    top: calc(50% - 0.5rem);
    animation: spinner-border 0.75s linear infinite;
  }
}

.tbl-action {
  .btn {
    border: none !important;
    padding: 0 !important;
    background: none !important;

    &:hover {
      background: none !important;
    }

    &:focus {
      border-color: none !important;
      box-shadow: none !important;
    }
  }
}

.btn-xs {
  padding: calc(.25rem) 0.25rem !important;
  display: inline-block;
  font-size: 10px !important;
  font-weight: $badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  margin-top: 1px;
  @include border-radius($badge-border-radius);
  height: 1.25rem;
}

.btn {
  &.btn-xs {
    font-size: 0.625rem !important;
    text-transform: uppercase;
    letter-spacing: .04em;
  }
}

$btn-colors: $theme-colors;

@if $enable-extra-colors {
  $btn-colors: map-merge($btn-colors, $colors);
}

@if $enable-social-colors {
  $btn-colors: map-merge($btn-colors, $social-colors);
}

@each $name,
$color in $btn-colors {

  .btn-#{$name},
  .btn-outline-#{$name},
  .btn-ghost-#{$name} {
    --#{$variable-prefix}btn-color: #{hex-to-rgb($color)};
    --#{$variable-prefix}btn-color-darker: #{hex-to-rgb(
 theme-color-darker($color))
  }

  ;
  --#{$variable-prefix}btn-color-text: #{color-contrast($color)};
}

.btn-#{$name} {
  @extend %btn-color;
}

.btn-outline-#{$name} {
  @extend %btn-outline;
}

.btn-ghost-#{$name} {
  @extend %btn-ghost;
}
}